import { useContext, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { EDITOR_ONBOARDING_BLUEPRINT_KEY } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

import type { Creatable, CanHandle, Handle } from '../createContentFromTemplateTypes';
import { processDraftUrl } from '../helpers';
import type {
	CreatePageByBlueprintMutation as CreateBlueprintResponse,
	CreatePageByBlueprintMutationVariables,
} from '../queries/__types__/CreatePageByBlueprintMutation';
import { CreatePageByBlueprintMutation } from '../queries/CreatePageByBlueprint.experimentalgraphql';
import { GetPersonalSpaceKeyQuery } from '../queries/GetPersonalSpaceKeyQuery.graphql';
import type { GetPersonalSpaceKeyQuery as GetPersonalSpaceKeyQueryType } from '../queries/__types__/GetPersonalSpaceKeyQuery';

export const useEditorOnboardingBlueprintCreatable = (): Creatable => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isLicensed } = useSessionData();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [createFromBlueprintMutation] = useMutation<
		CreateBlueprintResponse,
		CreatePageByBlueprintMutationVariables
	>(CreatePageByBlueprintMutation); // eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi

	// this has to be done via getApolloClient() because neither useQuery or useLazyQuery return a promise with the data, they return void.
	const getPersonalSpaceKey = useCallback(async () => {
		let personalSpaceData;
		if (isLicensed) {
			try {
				personalSpaceData = (
					await getApolloClient().query<GetPersonalSpaceKeyQueryType>({
						query: GetPersonalSpaceKeyQuery,
					})
				).data;
			} catch (personalSpaceError) {
				markErrorAsHandled(personalSpaceError);
				getMonitoringClient().submitError(personalSpaceError, {
					attribution: Attribution.CC_ONBOARDING,
				});

				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						action: 'failed',
						actionSubject: 'getPersonalSpaceKey',
						actionSubjectId: 'editorOnboardingTutorial',
						source: 'useEditorOnboardingBluePrintCreatable',
					},
				}).fire();
			}
		}

		const hasPersonalSpace = personalSpaceData?.user?.confluence?.hasPersonalSpace;
		const personalSpaceKey = hasPersonalSpace
			? personalSpaceData?.user?.confluence?.space?.key
			: undefined;

		return { hasPersonalSpace, personalSpaceKey };
	}, [isLicensed, createAnalyticsEvent]);

	const canHandle: CanHandle = useCallback((template) => {
		return template.blueprintModuleCompleteKey === EDITOR_ONBOARDING_BLUEPRINT_KEY;
	}, []);

	const handle: Handle = useCallback(
		async (template, spaceKey, options = {}) => {
			const { title, parentPageId } = options;
			/*
			 * This will create the draft under the root directory of the user's personal space.
			 * Fallback to current spaceKey (should be rare unless GetPersonalSpaceKeyQuery fails or
			 * when user immediately clicks on Editor Tutorial Template before loading is done).
			 */
			const { personalSpaceKey } = await getPersonalSpaceKey();
			const createFromBlueprintResponse = await createFromBlueprintMutation({
				variables: {
					spaceKey: personalSpaceKey || spaceKey,
					title,
					parentPageId: personalSpaceKey ? undefined : parentPageId,
					blueprint: JSON.stringify(template),
					attemptSpaUrlResponse: false,
				},
			});

			const draftUrl =
				createFromBlueprintResponse?.data?.experimentalPublishPageByBlueprint?.links?.draftUrl;
			window.open(processDraftUrl(draftUrl), '_blank', 'noreferrer');

			experienceTracker.succeed({
				name: CREATE_PAGE_EXPERIENCE,
			});
			return true;
		},
		[createFromBlueprintMutation, experienceTracker, getPersonalSpaceKey],
	);

	return useMemo(() => ({ canHandle, handle }), [canHandle, handle]);
};
