import { useContext, useCallback, useMemo } from 'react';
import idx from 'idx';
import { useMutation } from '@apollo/react-hooks';

import { usePageRestrictionsContext } from '@confluence/page-restrictions-context';
import {
	confluenceLocalStorageInstance as localStorage,
	keys as LOCALSTORAGE_KEYS,
} from '@confluence/storage-manager';
import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { Creatable, CanHandle, Handle } from '../createContentFromTemplateTypes';
import { processDraftUrl } from '../helpers';
import type {
	CreateFabricBlogpostMutation as CreateFabricBlogpostResponse,
	CreateFabricBlogpostMutationVariables,
} from '../queries/__types__/CreateFabricBlogpostMutation';
import { CreateFabricBlogpostMutation } from '../queries/CreateBlogpost.experimentalgraphql';

import { useNavigation } from './useNavigation';

export const useBlogpostCreatable = (): Creatable => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const navigate = useNavigation();
	const isEditorPage = useIsEditorPage();
	const [createFabricBlogpostMutation] = useMutation<
		CreateFabricBlogpostResponse,
		CreateFabricBlogpostMutationVariables
	>(CreateFabricBlogpostMutation); // eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi

	const { restrictions } = usePageRestrictionsContext();

	const canHandle: CanHandle = useCallback(
		(template) =>
			template.itemModuleCompleteKey ===
			'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blog-post',
		[],
	);

	const handle: Handle = useCallback(
		async (_, spaceKey, options = {}) => {
			const { title, openContentInNewTab } = options;
			// ToDo: Set attemptSpaUrlResponse to always be true and verify that there are no longer editor->editor
			//  transition bugs (https://product-fabric.atlassian.net/browse/MODES-3741)
			const createBlogpostResponse = await createFabricBlogpostMutation({
				variables: {
					spaceKey,
					returnSpaUrl: !isEditorPage,
					title,
				},
			});
			const draftUrl = idx(
				createBlogpostResponse,
				(_) => _.data.experimentalCreateFabricBlogpost.links.draftUrl,
			);

			const url = processDraftUrl(draftUrl);
			// if your in the editor, pressing browser back shouldn't land back you in the previous page editor.
			if (isEditorPage) {
				if (restrictions?.hasRestrictions) {
					localStorage.setItem(LOCALSTORAGE_KEYS.BLOGPOST_PERMISSIONS_NOTICE, true);
				}
				navigate(url, {
					openContentInNewTab,
					isReplaceAction: true,
				});
			} else {
				navigate(url, {
					openContentInNewTab,
				});
			}

			experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });

			return true;
		},
		[createFabricBlogpostMutation, experienceTracker, isEditorPage, navigate, restrictions],
	);

	return useMemo(() => ({ canHandle, handle }), [canHandle, handle]);
};
