import { useCallback, useContext } from 'react';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import { Attribution, isUnauthorizedError, isNotFoundError } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import {
	isBadThirdPartyBlueprintError,
	isBadEditedFirstPartyBlueprintError,
	isInvalidSpaceKeyError,
	isRateExceededError,
} from '@confluence/template-utils';

import type { Creatable, HandleSelect } from './createContentFromTemplateTypes';
import {
	useBlogpostCreatable,
	useCalendarCreatable,
	useBlueprintCreatable,
	useTemplateCreatable,
	useEditorOnboardingBlueprintCreatable,
} from './creatables';
import {
	failExperienceTracking,
	startExperienceTracking,
	succeedExperienceTracking,
	abortDueToExceededRateLimitExperienceTracking,
} from './experienceTracking';

export const useCreateContentFromTemplate = (): HandleSelect => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	// these Creatable custom hooks all have
	// canHandle function that checks if the template is the right type for that Creatable
	// and handle function that creates the content from the template
	const editorOnboardingCreatable = useEditorOnboardingBlueprintCreatable();
	const blogpostCreatable = useBlogpostCreatable();
	const calendarCreatable = useCalendarCreatable();
	const blueprintCreatable = useBlueprintCreatable();
	const templateCreatable = useTemplateCreatable();

	return useCallback(
		async (template: any, spaceKey: any, options = {}): Promise<void> => {
			const creatables: Creatable[] = [
				editorOnboardingCreatable,
				blogpostCreatable,
				calendarCreatable,
				blueprintCreatable,
				templateCreatable,
			];

			startExperienceTracking();
			try {
				// looks for the first Creatable that can handle the template
				const creatable = creatables.find(({ canHandle }) => canHandle(template));

				// if a Creatable is found, it will call handle method of that creatable to create the content and return true if succesful
				if (creatable) {
					const createdContent = await creatable.handle(template, spaceKey, options);
					// if createdContent is true, it means the content was created successfully
					// createdContent will be false if a variable input component was opened instead.
					if (createdContent) {
						succeedExperienceTracking();
					}
				} else {
					// if no Creatable can handle the template, then throw an error
					throw new Error(`Cant handle this template: ${JSON.stringify(template)}`);
				}
			} catch (error) {
				markErrorAsHandled(error);

				// Handle known errors
				if (isRateExceededError(error)) {
					abortDueToExceededRateLimitExperienceTracking();
				} else if (
					isNotFoundError(error) ||
					isUnauthorizedError(error) ||
					isBadThirdPartyBlueprintError(error) ||
					isBadEditedFirstPartyBlueprintError(error) ||
					isInvalidSpaceKeyError(error)
				) {
					// Normally this is tracked in the individual handlers,
					// but we will only get 403s/bad 3rd party blueprints on creation attempts
					// so we know the whole experience is over, unlike opening a wizard.
					experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });
					succeedExperienceTracking();

					// Re-throw specific errors that need to bubble up
					if (isNotFoundError(error)) {
						throw error;
					}
				} else {
					// Handle unexpected errors
					getMonitoringClient().submitError(error, {
						attribution: Attribution.TAILORED_EXPERIENCES,
					});
					failExperienceTracking(error);
				}

				// Re-throw the original error for further handling if needed
				throw error;
			}
		},
		[
			experienceTracker,
			editorOnboardingCreatable,
			blogpostCreatable,
			calendarCreatable,
			blueprintCreatable,
			templateCreatable,
		],
	);
};
