import {
	CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE,
	CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE,
	BLUEPRINT_WIZARD_LOAD_DIALOG_EXPERIENCE,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
	stopExperienceOnError,
	collectAny,
	hasName,
	CREATE_PAGE_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getTraceIdFromApolloError } from '@confluence/template-utils';

export const startExperienceTracking = (): void => {
	getExperienceTracker().start({
		name: CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE,
		collect(events, experience) {
			collectAny([CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE])(events, experience);

			experience.stopOn(
				events.find(
					(event) =>
						(event.action === 'taskFail' || event.action === 'taskAbort') &&
						hasName(event, BLUEPRINT_WIZARD_LOAD_DIALOG_EXPERIENCE),
				),
			);
		},
	});
};

export const failExperienceTracking = (error: Error): void => {
	const cteTraceId = getTraceIdFromApolloError(error);
	stopExperienceOnError(CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE, error, {
		cteTraceId,
	});
};

export const succeedExperienceTracking = (): void => {
	getExperienceTracker().succeed({
		name: CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE,
	});
};

export const abortDueToExceededRateLimitExperienceTracking = (): void => {
	const experienceTracker = getExperienceTracker();
	const reason = 'Rate limit exceeded while attempting to create content from a template';

	experienceTracker.abort({
		name: CREATE_PAGE_EXPERIENCE,
		reason,
	});

	experienceTracker.abort({
		name: CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE,
		reason,
	});
};
